export default function useMoneyFormater () {
  function formatMoneyWithCurrency (number: number, currency: 'RUB' | 'USD' = 'RUB') {
    const defaultFormater = new Intl.NumberFormat('ru-RU', {
      style: 'currency',
      currency,
      minimumFractionDigits: 0
    })

    return defaultFormater.format(number)
  }

  function moneyFormater (number: number) {
    const defaultFormater = new Intl.NumberFormat('ru-RU', {
      style: 'currency',
      currency: 'RUB',
      minimumFractionDigits: 0
    })

    return defaultFormater.format(number)
  }

  return { formatMoneyWithCurrency, moneyFormater }
}
