import { computed, Ref, ref } from "vue";

import {
  useCartStore,
  type CartEntry,
  type Cart,
} from "../stores/use-cart-store";

import { type IProduct } from "../types";
const { formatMoneyWithCurrency } = useMoneyFormater();

export function useCart(product?: Ref<IProduct>) {
  const store = useCartStore();

  const { $post } = useAxios();

  const isInCart = computed<boolean>(() => {
    return Object.keys(store.cart).includes(product.public_id);
  });

  const productInfo = computed<CartEntry | undefined>(() => {
    if (isInCart.value) {
      return cart.value[product.public_id];
    }
    return undefined;
  });

  const cart = computed<Cart>(() => {
    return store.cart;
  });

  const productTotal = computed(() => {
    let productId = product?.public_id ?? "";

    if (productId) {
      return cart.value[productId].total;
    }
    return 0;
  });

  function getProductAmount(_product: IProduct) {
    let total = cart.value[_product.public_id].total;
    let sum = _product.price * total;
    return formatMoneyWithCurrency(sum);
  }

  function calcProductSummaryAmount(products: IProduct[]) {
    let sum = 0;
    products.forEach((_product) => {
      let total = cart.value[_product.public_id].total;
      sum += _product.price * total;
    });
    return sum;
  }

  const length = computed(() => {
    return Object.keys(store.cart).length;
  });

  function getLocalCart() {
    return new Map<string, CartEntry>(Object.entries(store.cart));
  }

  function updateCart(cart: Map<string, CartEntry>): void {
    store.setCart(Object.fromEntries(cart.entries()));
  }

  function addProductToCart() {
    try {
      const _cart = getLocalCart();
      if (isInCart.value) {
        _cart.set(product.public_id, {
          item: product.public_id,
          total: _cart.get(product.public_id).total + 1,
        });
      } else {
        _cart.set(product.public_id, {
          item: product.public_id,
          total: 1,
        });
      }
      updateCart(_cart);
    } catch (error) {}
  }

  function clearCart() {
    updateCart(new Map());
  }

  function removeProductFromCart(count?: number) {
    const _cart = getLocalCart();

    if (count) {
      if (_cart.get(product.public_id).total > count)
        _cart.set(product.public_id, {
          item: product.public_id,
          total: _cart.get(product.public_id).total - count,
        });
      else _cart.delete(product.public_id);
    } else _cart.delete(product.public_id);

    updateCart(_cart);
  }

  function removeProductbyID(id: string) {
    const _cart = getLocalCart();

    _cart.delete(id);
    updateCart(_cart);
  }

  function removeFromCart(count?: number) {
    removeProductFromCart(count);
  }

  function addToCartProducts(products?: any) {
    products.forEach((element: any) => {
      const _cart = getLocalCart();
      try {
        _cart.set(element.public_id, {
          item: element.public_id,
          total: _cart.get(element.public_id).total + 1,
        });
        updateCart(_cart);
      } catch (error) {
        _cart.set(element.public_id, {
          item: element.public_id,
          total: 1
        });
        updateCart(_cart);
      }
    });
  }

  async function createOrder() {
    //create order
  }

  return {
    isInCart,
    productInfo,
    productTotal,
    removeProductFromCart,
    addProductToCart,
    length,
    clearCart,
    cart,
    calcProductSummaryAmount,
    removeProductbyID,
    addToCartProducts,
    // createOrder,
    getProductAmount,
  };
}
